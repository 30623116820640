export interface PaginationIndex {
  current_page: number;
  first_page_url: string;
  from: number;
  last_page: number;
  last_page_url: string;
  next_page_url: string;
  path: string;
  per_page: string;
  prev_page_url: string;
  to: number;
  total: number;
}

export class PaginationIndex implements PaginationIndex {
  current_page: number;
  first_page_url: string;
  from: number;
  last_page: number;
  last_page_url: string;
  next_page_url: string;
  path: string;
  per_page: string;
  prev_page_url: string;
  to: number;
  total: number;

  constructor() {
    this.current_page = 1;
    this.first_page_url = "";
    this.from = 1;
    this.last_page = 1;
    this.last_page_url = "";
    this.next_page_url = "";
    this.path = "";
    this.per_page = "";
    this.prev_page_url = "";
    this.to = 1;
    this.total = 1;
  }
}
